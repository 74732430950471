import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  PlacePreviewDto,
  PlacesApiService,
  RegionDto,
  RegionsApiService,
  RouteDto,
  RoutePlaceMetaDto,
  RoutePreviewDto,
  RoutesApiService,
  RouteStepDto,
} from '../../core/api/generated/abuduba-api';
import { ActivatedRoute } from '@angular/router';
import { IGalleryFile } from '../../core/gallery/gallery.component';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { MapboxComponent } from '../../core/mapbox/mapbox.component';
import mapboxgl, { Marker } from 'mapbox-gl';
import {
  formatDistance,
  formatMinutes,
  getMainPictureUrl,
} from '../../places/places.utils';
import { getTypeIcon, getTypeName } from '../routes.utils';
import { IBreadcrumb } from '../../core/breadcrumb/breadcrumb.component';
import { Meta, Title } from '@angular/platform-browser';
import { ApiHelper } from '../../core/api/api.helper';
import { orderBy } from 'lodash';
import { CreateSuggestionDtoEntityTypeEnum } from '../../core/api/generated/abuduba-api/shared-enums';
import { forkJoin, switchMap } from 'rxjs';
import { skip } from 'rxjs/operators';

dayjs.extend(utc);

@Component({
  selector: 'app-route-full-page',
  styleUrls: ['route-full-page.component.scss'],
  templateUrl: 'route-full-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouteFullPageComponent implements OnInit {
  public route?: RouteDto | null;
  public places: PlacePreviewDto[] = [];
  public placesMap?: Map<number, PlacePreviewDto>;
  public nearestRoutes?: (RoutePreviewDto & { distance?: number })[];
  public region?: RegionDto;

  @ViewChild(MapboxComponent)
  private mapComponent?: MapboxComponent;

  private markers: Marker[] = [];
  private mapSourceId = 'route';
  private mapLayerId = 'route-full-page-line';

  constructor(
    private readonly placesApiService: PlacesApiService,
    private readonly routesApiService: RoutesApiService,
    private readonly regionsApiService: RegionsApiService,
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private titleService: Title,
    private metaService: Meta,
    private apiHelper: ApiHelper,
  ) {
    titleService.setTitle('Abuduba - Route Information');
  }

  getBreadcrumbs(): IBreadcrumb[] {
    if (!this.route || !this.region) {
      return [];
    }

    return [
      {
        label: 'Home',
        url: '/',
      },
      {
        label: this.region.name,
        url: `/regions/${this.region.index}`,
      },
      {
        label: 'Routes',
        url: `/routes`,
        qs: {
          regions: [this.region.id],
        },
      },
      {
        label: this.route.name,
        url: `/routes/${this.route.index}`,
      },
    ];
  }

  get media(): IGalleryFile[] | undefined {
    return this.route?.files.map((f) => ({
      url: f.originalUrl,
      mediumUrl: f.mediumSizeUrl,
      thumbUrl: f.smallSizeUrl,
    }));
  }

  ngOnInit(): void {
    this.updateData();

    if (this.apiHelper.isBrowser) {
      this.activatedRoute.paramMap.pipe(skip(1)).subscribe(() => {
        this.updateData();
      });
    }
  }

  getStepPlaces(step: RouteStepDto): PlacePreviewDto[] {
    const places = [];

    for (const id of step.placeIds) {
      const foundPlace = this.placesMap?.get(id);

      if (foundPlace) {
        places.push(foundPlace);
      }
    }

    return places;
  }

  getPlacesMeta(): RoutePlaceMetaDto[] {
    const maxLength = 5;

    const list = orderBy(this.places, (p) => p.name.length, 'asc')
      .slice(0, maxLength)
      .map((p) => ({
        name: p.name,
        id: p.id,
      }));

    if (list.length < this.places.length) {
      list.push({
        name: `+${this.places.length - maxLength}`,
        id: 0,
      });
    }

    return list;
  }

  private generatePageMeta(data: RouteDto) {
    const title = `Abuduba - ${data.name}`;

    this.titleService.setTitle(title);
    this.metaService.updateTag({
      name: 'og:title',
      content: title,
    });

    this.metaService.updateTag({
      name: 'og:image',
      content: getMainPictureUrl(data.files, 'small'),
    });

    if (data.seoDescription) {
      this.metaService.updateTag({
        name: 'description',
        content: data.seoDescription,
      });
      this.metaService.updateTag({
        name: 'og:description',
        content: data.seoDescription,
      });
    }

    if (data.seoKeywords) {
      this.metaService.updateTag({
        name: 'keywords',
        content: data.seoKeywords,
      });
    }
  }

  public updateData(): void {
    const params = this.activatedRoute.snapshot.paramMap;
    const index = String(params.get('index'));

    this.routesApiService
      .getRouteByIndex({ index })
      .pipe(
        switchMap((routeData) => {
          this.route = routeData;
          this.generatePageMeta(routeData);

          // If in browser, set map route
          if (this.apiHelper.isBrowser) {
            setTimeout(() => {
              this.setMapRoute(routeData);
            }, 100);
          }

          // Fetch related data in parallel
          return forkJoin({
            region: this.regionsApiService.getRegionById({
              id: routeData.regionId,
            }),
            places: this.placesApiService.getPlacesPreview({
              ids: routeData.placeIds,
            }),
            nearestRoutes: this.routesApiService.getNearestRoutes({
              index: routeData.index,
              limit: 3,
            }),
          });
        }),
      )
      .subscribe({
        next: ({ region, places, nearestRoutes }) => {
          // Update state with fetched data
          this.region = region;

          this.placesMap = new Map(places.map((place) => [place.id, place]));
          this.places = places;

          this.nearestRoutes = nearestRoutes.map((r) => ({
            ...r.route,
            distance: r.distance,
          }));

          // Trigger change detection once
          this.changeDetectorRef.detectChanges();
        },
        error: (err) => {
          if (err?.status === 404) {
            this.route = null;
          } else {
            console.error('Error fetching route data:', err);
          }
        },
      });
  }

  private cleanMap() {
    for (const m of this.markers) {
      m.remove();
    }

    this.markers = [];

    const map = this.mapComponent?.map;

    if (map && map.getSource(this.mapSourceId)) {
      map.removeLayer(this.mapLayerId);
      map.removeSource(this.mapSourceId);
    }
  }

  public setMapRoute(route: RouteDto) {
    const map = this.mapComponent?.map;

    if (!map) {
      return;
    }

    this.mapComponent?.onMapLoad(() => {
      this.cleanMap();
      this.mapComponent?.resize();

      let index = 1;
      for (const p of route.route) {
        const coordinates = {
          lng: p.x,
          lat: p.y,
        };
        const step = route.steps[index - 1];
        const places = this.getStepPlaces(step);

        const html = `
          <div class="step-marker">
          <div class="title">${index++}</div>
          <div class="places">${places
            .map(
              (p) =>
                `<div style='background: url(${getMainPictureUrl([p.preview], 'thumb')}) no-repeat center center'></div>`,
            )
            .slice(0, 4)
            .join('')}</div>
          </div>`;

        // Create a HTML element for each marker
        const markerElement = document.createElement('div');
        markerElement.innerHTML = html;
        markerElement.style.zIndex = '1';
        markerElement.style.width = '50px';
        markerElement.style.height = '50px';
        markerElement.style.borderRadius = '50%';
        markerElement.style.overflow = 'hidden';

        markerElement.addEventListener('mouseenter', () => {
          markerElement.style.zIndex = '3';
        });

        markerElement.addEventListener('mouseout', () => {
          markerElement.style.zIndex = '1';
        });

        const marker = new mapboxgl.Marker(markerElement)
          .setLngLat(coordinates)
          .addTo(map);
        this.markers.push(marker);
      }

      if (route.route.length > 1) {
        map.addSource(this.mapSourceId, {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: route.route.map((r) => [r.x, r.y]),
            },
          },
        });

        map.addLayer({
          id: this.mapLayerId,
          type: 'line',
          source: this.mapSourceId,
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#4264fb',
            'line-width': 4,
          },
        });
      }
    });
  }

  protected readonly getTypeIcon = getTypeIcon;
  protected readonly getTypeName = getTypeName;
  protected readonly formatMinutes = formatMinutes;
  protected readonly formatDistance = formatDistance;
  protected readonly CreateSuggestionDtoEntityTypeEnum =
    CreateSuggestionDtoEntityTypeEnum;
}
