import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  RoutePlaceMetaDto,
  RoutePreviewDto,
} from '../../core/api/generated/abuduba-api';
import { getTypeName } from '../routes.utils';
import {
  formatDistance,
  formatMinutes,
  getMainPictureUrl,
  removeHtmlTags,
} from '../../places/places.utils';
import { orderBy } from 'lodash';

dayjs.extend(utc);

@Component({
  selector: 'app-route-preview',
  styleUrls: ['route-preview.component.scss'],
  templateUrl: 'route-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoutePreviewComponent {
  @Input()
  public route: RoutePreviewDto & { distance?: number };

  @Input()
  public horizontal = false;

  @Input()
  public newTab = false;

  protected readonly getTypeName = getTypeName;
  protected readonly formatMinutes = formatMinutes;
  protected readonly formatDistance = formatDistance;
  protected readonly getMainPictureUrl = getMainPictureUrl;

  getPlacesMeta(): RoutePlaceMetaDto[] {
    return orderBy(this.route.placesMeta, (p) => p.name.length, 'asc').slice(
      0,
      this.horizontal ? 5 : 3,
    );
  }

  protected readonly removeHtmlTags = removeHtmlTags;
}
