import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import {
  PlaceCollectionPreviewDto,
  PlaceCollectionsApiService,
} from '../../core/api/generated/abuduba-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { getMainPictureUrl } from '../places.utils';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { GetPlaceCollectionsPreviewSortByEnum } from '../../core/api/generated/abuduba-api/shared-enums';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

dayjs.extend(utc);

@Component({
  selector: 'app-place-collections-page',
  styleUrls: ['place-collections-page.component.scss'],
  templateUrl: 'place-collections-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaceCollectionsPageComponent implements OnInit {
  public collections: PlaceCollectionPreviewDto[];
  public totalItems = 0;
  public totalFilteredItems = 0;

  public isHorizontal = true;
  public page = 1;
  public limit = 15;
  public isLoading = false;

  private destroy$ = new Subject<void>();

  constructor(
    private readonly placeCollectionsApiService: PlaceCollectionsApiService,
    private readonly breakpointObserver: BreakpointObserver,
    private titleService: Title,
    private metaService: Meta,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
  ) {
    this.generatePageMeta();
  }

  public generatePageMeta() {
    const title = 'Abuduba - Collections';
    const description =
      'Discover Abuduba’s exclusive collections of top places and experiences in the UAE. Explore curated lists featuring the best beaches, tallest buildings, cultural sites, and more across popular emirates like Dubai and Abu Dhabi.';
    const keywords =
      'Abuduba collections UAE, best beaches UAE, tallest buildings UAE, Dubai collections, Abu Dhabi collections, top places UAE, cultural sites UAE, Abuduba service, explore UAE, popular emirates, UAE attractions';

    this.titleService.setTitle(title);
    this.metaService.updateTag({
      name: 'og:title',
      content: title,
    });

    this.metaService.updateTag({
      name: 'description',
      content: description,
    });
    this.metaService.updateTag({
      name: 'og:description',
      content: description,
    });

    this.metaService.updateTag({
      name: 'og:image',
      content: `${environment.dashboardUrl}/assets/logo.png`,
    });

    this.metaService.updateTag({
      name: 'keywords',
      content: keywords,
    });
  }

  ngOnInit() {
    this.parseQueryParams();
    this.updateData();

    this.breakpointObserver
      .observe('(max-width: 1000px)')
      .subscribe((result) => {
        this.isHorizontal = !result.matches;
        this.changeDetectorRef.detectChanges();
      });
  }

  public parseQueryParams() {
    const params = this.route.snapshot.queryParamMap;

    const page = parseInt(String(params.get('page')));
    if (page) {
      this.page = page;
    }

    this.changeDetectorRef.markForCheck();
  }

  public get totalPages() {
    return Math.ceil(this.totalFilteredItems / this.limit) || 1;
  }

  private updateData() {
    this.isLoading = true;

    this.destroy$.next();

    this.placeCollectionsApiService
      .searchPlaceCollectionsPreview({
        limit: this.limit,
        offset: (this.page - 1) * this.limit,
        sortBy: GetPlaceCollectionsPreviewSortByEnum.Favourite,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.collections = data.items;
          this.totalItems = data.totalItems;
          this.totalFilteredItems = data.totalFilteredItems;
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        },
        complete: () => {
          this.changeDetectorRef.detectChanges();
        },
      });
  }

  public onPageChanged(page: number) {
    this.page = page;

    this.updateData();
  }

  protected readonly getMainPictureUrl = getMainPictureUrl;
}
