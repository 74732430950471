<div id="routes_list" class="content-wrap" [class.map-view]="isMapView">
  <div class="header">
    <div class="filters">
      <div class="filters-button" (click)="isFiltersOpen = true">
        <ng-icon name="matFilterAltOutline"></ng-icon>
        Filters
      </div>

      <div class="map-filter">
        <div
          class="map-button"
          *ngIf="!isMapView"
          (click)="toggleMapView(true)">
          <ng-icon name="matMapOutline" *ngIf="!isMapLoading"></ng-icon>
          <div class="loading-animation" *ngIf="isMapLoading"></div>
          Show map
        </div>

        <div
          class="list-button"
          *ngIf="isMapView"
          (click)="toggleMapView(false)">
          <ng-icon name="matListOutline"></ng-icon>
          Show list
        </div>
      </div>

      <div
        class="locate-block"
        (click)="updateCurrentPosition()"
        *ngIf="userLocation === null">
        <ng-icon name="matMyLocationOutline"></ng-icon>
        <span>Locate me</span>
      </div>
    </div>

    <div class="header-group">
      <div class="title-block">
        <div class="title">
          <h1>Discover routes</h1>
          <div class="loading-animation" *ngIf="isLoading"></div>
        </div>
        <div class="totals">
          <div class="total">{{ totalFilteredItems }} results</div>
        </div>
      </div>

      <div class="actions-block">
        <div
          class="locate-block"
          (click)="updateCurrentPosition()"
          *ngIf="userLocation === null">
          <ng-icon name="matMyLocationOutline"></ng-icon>
          <span>Locate me</span>
        </div>

        <div class="sorting-block" *ngIf="!isMapView">
          <app-custom-select
            icon="bootstrapSortDown"
            title="Sort By"
            (valueChange)="updateSortBy($event)"
            [items]="[
              {
                label: 'Shortest Duration',
                value: 'DurationAsc'
              },
              {
                label: 'Longest Duration',
                value: 'DurationDesc'
              },
              {
                label: 'Shortest Distance',
                value: 'DistanceAsc'
              },
              {
                label: 'Longest Distance',
                value: 'DistanceDesc'
              }
            ]"></app-custom-select>
        </div>
      </div>
    </div>
  </div>

  <div class="content">
    <div
      id="filters"
      [class.open]="isFiltersOpen"
      (click)="closeFilters($event)">
      <div class="filters-body shadow-block">
        <div class="header">
          <div class="title">Filters</div>
          <div class="actions">
            <div class="close" (click)="isFiltersOpen = false">
              <ng-icon name="matCloseOutline"></ng-icon>
            </div>
          </div>
        </div>
        <div class="filters-list">
          <app-filter-checkbox
            [options]="typesSelectOptions"
            (valueChange)="onFiltersChange()"
            [(value)]="typesFilter"
            title="Types"></app-filter-checkbox>

          <app-filter-checkbox
            [options]="regionsFilterOptions"
            (valueChange)="onFiltersChange()"
            [(value)]="regionsFilter"
            title="Regions"></app-filter-checkbox>

          <app-filter-slider
            [min]="0"
            [max]="24 * 60"
            (valueChange)="updateDurationFilter($event)"
            [format]="formatDuration.bind(this)"
            [step]="5"
            title="Duration"></app-filter-slider>

          <app-filter-slider
            [min]="0"
            [max]="1000 * 100"
            (valueChange)="updateDistanceFilter($event)"
            [format]="formatDistance.bind(this)"
            [step]="100"
            title="Distance"></app-filter-slider>
        </div>
        <div class="show-button" (click)="isFiltersOpen = false">
          Show {{ totalFilteredItems }} results
        </div>
      </div>
    </div>

    <div class="routes-content">
      <div class="error-message" *ngIf="userLocationError">
        {{ userLocationError }}
      </div>

      <div id="nearest_routes" *ngIf="nearestRoutes">
        <div class="nearest-routes-list">
          <app-route-near-by
            *ngFor="let p of nearestRoutes"
            [route]="p.route"
            [distance]="p.distance"></app-route-near-by>
        </div>
      </div>

      <div id="routes_map_body" *ngIf="isMapView">
        <app-places-map
          [loading]="isMapLoading"
          [center]="userLocation || undefined"></app-places-map>
      </div>

      <div id="routes_list_body" *ngIf="!isMapView">
        <div class="not-found" *ngIf="routes?.length === 0 && !isLoading">
          Routes not found
        </div>

        <app-loader *ngIf="isLoading && !routes"></app-loader>

        <div class="list">
          <app-route-preview
            *ngFor="let route of routes; trackBy: routeTrackBy"
            [horizontal]="isHorizontal"
            [newTab]="true"
            [route]="route"></app-route-preview>
        </div>

        <div class="pagination-block" *ngIf="routes && routes.length > 0">
          <app-pagination
            [totalPages]="totalPages"
            [currentPage]="page"
            (pageChanged)="onPageChanged($event)"
            [isLoading]="isLoading"></app-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
