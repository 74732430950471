import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  NearestRestaurantDto,
  RegionDto,
  RegionsApiService,
  RestaurantDailyOpenHoursDto,
  RestaurantDto,
  RestaurantsApiService,
} from '../../core/api/generated/abuduba-api';
import { ActivatedRoute } from '@angular/router';
import { IGalleryFile } from '../../core/gallery/gallery.component';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { sortBy, upperFirst } from 'lodash';
import { IBreadcrumb } from '../../core/breadcrumb/breadcrumb.component';
import { ApiHelper } from '../../core/api/api.helper';
import { CurrencyManager } from '../../core/currency-manager';
import { Meta, Title } from '@angular/platform-browser';
import { CreateSuggestionDtoEntityTypeEnum } from '../../core/api/generated/abuduba-api/shared-enums';
import { PlacesMapComponent } from '../../core/places-map/places-map.component';
import {
  formatWorkingTime,
  getCurrentWorkingTime,
  getMainPictureUrl,
  isOpenNow,
} from '../../places/places.utils';
import { getCuisineName, getPriceLevelName } from '../restaurants.utils';
import { forkJoin, switchMap } from 'rxjs';
import { skip } from 'rxjs/operators';

dayjs.extend(utc);

@Component({
  selector: 'app-restaurant-full-page',
  styleUrls: ['restaurant-full-page.component.scss'],
  templateUrl: 'restaurant-full-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestaurantFullPageComponent implements OnInit {
  public restaurant?: RestaurantDto | null;
  public nearestRestaurants?: NearestRestaurantDto[];
  public region?: RegionDto;
  public isScheduleExpand = false;
  public isDescriptionOpen = false;

  @ViewChild(PlacesMapComponent)
  private mapComponent: PlacesMapComponent;

  constructor(
    private readonly restaurantsApiService: RestaurantsApiService,
    private readonly regionsApiService: RegionsApiService,
    private route: ActivatedRoute,
    public readonly apiHelper: ApiHelper,
    public readonly currencyManager: CurrencyManager,
    private titleService: Title,
    private metaService: Meta,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    titleService.setTitle('Abuduba - Restaurant Information');
  }

  getBreadcrumbs(): IBreadcrumb[] {
    if (!this.restaurant || !this.region) {
      return [];
    }

    return [
      {
        label: 'Home',
        url: '/',
      },
      {
        label: this.region.name,
        url: `/regions/${this.region.index}`,
      },
      {
        label: 'Restaurants',
        url: `/restaurants`,
        qs: {
          regions: [this.region.id],
        },
      },
      {
        label: this.restaurant.name,
        url: `/restaurants/${this.restaurant.index}`,
      },
    ];
  }

  get media(): IGalleryFile[] | undefined {
    return this.restaurant?.files.map((f) => ({
      url: f.originalUrl,
      mediumUrl: f.mediumSizeUrl,
      thumbUrl: f.smallSizeUrl,
    }));
  }

  ngOnInit(): void {
    this.updateData();

    if (this.apiHelper.isBrowser) {
      this.route.paramMap.pipe(skip(1)).subscribe(() => {
        this.updateData();
      });
    }
  }

  public get restaurantWorkingTime(): {
    hours: RestaurantDailyOpenHoursDto;
    dayName: string;
  }[] {
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];

    const schedule = this.restaurant
      ? Object.entries(this.restaurant.workingTime).map(([key, v]) => ({
          hours: v,
          dayName: upperFirst(key),
          dayIndex: days.indexOf(upperFirst(key)),
        }))
      : [];
    return sortBy(schedule, 'dayIndex');
  }

  public renderMap() {
    if (!this.mapComponent) {
      return;
    }

    this.mapComponent.mapboxElement.onMapLoad(() => {
      if (this.restaurant) {
        this.mapComponent.renderRestaurants([
          {
            data: {
              ...this.restaurant,
              preview: this.restaurant.files[0],
            },
            highlight: true,
          },
          ...(this.nearestRestaurants?.map((p) => ({
            data: p.restaurant,
            distance: p.distance,
          })) || []),
        ]);

        setTimeout(() => {
          this.highlightItemOnMap(this.restaurant!.index, true);
        }, 0);
      }
    });
  }

  public highlightItemOnMap(restaurantIndex: string, isActive = true) {
    if (isActive) {
      this.mapComponent.closePopup();
      this.mapComponent.moveMapToItem(restaurantIndex, true);
    }
  }

  private generatePageMeta(data: RestaurantDto) {
    const title = `Abuduba - ${data.name}`;

    this.titleService.setTitle(title);
    this.metaService.updateTag({
      name: 'og:title',
      content: title,
    });

    this.metaService.updateTag({
      name: 'og:image',
      content: getMainPictureUrl(data.files, 'small'),
    });

    if (data.seoDescription) {
      this.metaService.updateTag({
        name: 'description',
        content: data.seoDescription,
      });
      this.metaService.updateTag({
        name: 'og:description',
        content: data.seoDescription,
      });
    }

    if (data.seoKeywords) {
      this.metaService.updateTag({
        name: 'keywords',
        content: data.seoKeywords,
      });
    }
  }

  public updateData(): void {
    const params = this.route.snapshot.paramMap;
    const index = String(params.get('index'));

    this.restaurantsApiService
      .getRestaurantByIndex({ index })
      .pipe(
        switchMap((restaurantData) => {
          this.restaurant = restaurantData;
          this.generatePageMeta(restaurantData);

          // Fetch region and nearest restaurants in parallel
          return forkJoin({
            region: this.regionsApiService.getRegionById({
              id: restaurantData.regionId,
            }),
            nearestRestaurants:
              this.restaurantsApiService.getNearestRestaurants({
                index: restaurantData.index,
                limit: 15,
              }),
          });
        }),
      )
      .subscribe({
        next: ({ region, nearestRestaurants }) => {
          // Update component state
          this.region = region;
          this.nearestRestaurants = nearestRestaurants;

          // Trigger change detection
          this.changeDetectorRef.detectChanges();

          // Render map after the nearest restaurants are fetched
          setTimeout(() => {
            this.renderMap();
          }, 0);
        },
        error: (err) => {
          if (err?.status === 404) {
            this.restaurant = null;
          } else {
            console.error('Error fetching data:', err);
          }
        },
      });
  }

  protected readonly CreateSuggestionDtoEntityTypeEnum =
    CreateSuggestionDtoEntityTypeEnum;
  protected readonly getCuisineName = getCuisineName;
  protected readonly isOpenNow = isOpenNow;
  protected readonly getCurrentWorkingTime = getCurrentWorkingTime;
  protected readonly formatWorkingTime = formatWorkingTime;
  protected readonly getPriceLevelName = getPriceLevelName;
}
