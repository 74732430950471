import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  NearestRestaurantDto,
  RegionDto,
  RegionsApiService,
  RestaurantPreviewDto,
  RestaurantsApiService,
} from '../../core/api/generated/abuduba-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ActivatedRoute } from '@angular/router';
import { FilterCheckboxOption } from '../../core/filter-checkbox/filter-checkbox.component';
import { orderBy } from 'lodash';
import { SearchRestaurants$Params } from '../../core/api/generated/abuduba-api/fn/restaurants/search-restaurants';
import { CurrencyManager } from '../../core/currency-manager';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subject, takeUntil } from 'rxjs';
import { ListPopoverItem } from '../../core/custom-select/custom-select.component';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { PaginationComponent } from '../../core/pagination/pagination.component';
import {
  GetPlacesPreviewWorkingTimesEnum,
  GetRestaurantsPreviewSortByEnum,
} from '../../core/api/generated/abuduba-api/shared-enums';
import { SearchRestaurantsPreview$Params } from '../../core/api/generated/abuduba-api/fn/restaurants/search-restaurants-preview';
import {
  restaurantAwards,
  restaurantCuisines,
  restaurantHighlights,
  restaurantPriceLevels,
} from '../restaurants.types';
import { PlacesMapComponent } from '../../core/places-map/places-map.component';
import { MapCoordinates } from '../../core/mapbox/mapbox.component';
import { ApiHelper } from '../../core/api/api.helper';
import { Geolocation } from '@capacitor/geolocation';

dayjs.extend(utc);

@Component({
  selector: 'app-restaurants-list-page',
  styleUrls: ['restaurants-list-page.component.scss'],
  templateUrl: 'restaurants-list-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestaurantsListPageComponent implements OnInit {
  public restaurants?: RestaurantPreviewDto[];
  public nearestRestaurants?: NearestRestaurantDto[];
  public regions: RegionDto[] = [];
  public totalItems = 0;
  public totalFilteredItems = 0;

  public page = 1;
  public limit = 25;
  public isLoading = false;
  public isMapLoading = false;
  public isMapView = false;
  public isFiltersOpen = false;
  public isHorizontal = true;
  public userLocation?: MapCoordinates | null;
  public userLocationError?: string;

  public sortBy: SearchRestaurantsPreview$Params['sortBy'] =
    GetRestaurantsPreviewSortByEnum.Rating;

  private destroy$ = new Subject<void>();

  @ViewChild(PlacesMapComponent)
  public map: PlacesMapComponent;

  @ViewChild(PaginationComponent)
  public paginationComponent?: PaginationComponent;

  constructor(
    private readonly restaurantsApiService: RestaurantsApiService,
    private readonly regionsApiService: RegionsApiService,
    private route: ActivatedRoute,
    public readonly currencyManager: CurrencyManager,
    private readonly breakpointObserver: BreakpointObserver,
    private titleService: Title,
    private metaService: Meta,
    private changeDetectorRef: ChangeDetectorRef,
    private apiHelper: ApiHelper,
  ) {
    this.generatePageMeta();
  }

  public generatePageMeta() {
    const title = 'Abuduba - Explore Restaurants';
    const description =
      'Discover the best restaurants in the UAE with AbuDuba. From fine dining to casual spots, explore top-rated cuisines, hidden gems, and must-visit eateries.';
    const keywords =
      'best restaurants UAE, fine dining UAE, casual dining UAE, top UAE restaurants, dining in Dubai, Abu Dhabi restaurants, UAE food guide, best cuisines UAE';

    this.titleService.setTitle(title);
    this.metaService.updateTag({
      name: 'og:title',
      content: title,
    });

    this.metaService.updateTag({
      name: 'description',
      content: description,
    });
    this.metaService.updateTag({
      name: 'og:description',
      content: description,
    });

    this.metaService.updateTag({
      name: 'og:image',
      content: `${environment.dashboardUrl}/assets/logo.png`,
    });

    this.metaService.updateTag({
      name: 'keywords',
      content: keywords,
    });
  }

  public closeFilters(e: MouseEvent) {
    const barBody = document.querySelector('#filters .filters-body');
    const clickedInside = barBody?.contains(e.target as Node);

    if (!clickedInside) {
      this.isFiltersOpen = false;
    }
  }

  public toggleMapView(isMapView: boolean) {
    if (isMapView === this.isMapView) {
      return;
    }

    this.isMapView = isMapView;

    setTimeout(() => {
      if (this.isMapView) {
        this.updateMapRestaurants();
      } else {
        this.map?.cleanMap();
        this.updateRestaurants();
      }
    }, 0);
  }

  public awardsFilterOptions: FilterCheckboxOption[] = orderBy(
    Object.entries(restaurantAwards).map(([i, val]) => ({
      label: val.name,
      value: i,
    })),
    'label',
    'asc',
  );
  public awardsFilter: RestaurantPreviewDto['awards'] = [];

  public highlightsFilterOptions: FilterCheckboxOption[] = orderBy(
    Object.entries(restaurantHighlights).map(([i, val]) => ({
      label: val.name,
      value: i,
    })),
    'label',
    'asc',
  );
  public highlightsFilter: RestaurantPreviewDto['highlights'] = [];

  public priceLevelFilterOptions: FilterCheckboxOption[] = Object.entries(
    restaurantPriceLevels,
  ).map(([i, val]) => ({
    label: val.name,
    value: i,
  }));
  public priceLevelFilter: RestaurantPreviewDto['priceLevel'][] = [];

  public cuisinesFilterOptions: FilterCheckboxOption[] = orderBy(
    Object.entries(restaurantCuisines).map(([i, val]) => ({
      label: val.name,
      value: i,
    })),
    'label',
    'asc',
  );
  public cuisinesFilter: RestaurantPreviewDto['cuisines'] = [];

  public regionsFilterOptions: FilterCheckboxOption[] = [];
  public regionsFilter: number[] = [];

  public hoursFilterOptions: FilterCheckboxOption[] = [
    {
      label: 'Open now',
      value: GetPlacesPreviewWorkingTimesEnum.OpenNow,
    },
    {
      label: 'Morning (7AM-12PM)',
      value: GetPlacesPreviewWorkingTimesEnum.Morning,
    },
    {
      label: 'Afternoon (12PM-5PM)',
      value: GetPlacesPreviewWorkingTimesEnum.Afternoon,
    },
    {
      label: 'Evening (5PM-12AM)',
      value: GetPlacesPreviewWorkingTimesEnum.Evening,
    },
    {
      label: 'Night (12AM-7AM)',
      value: GetPlacesPreviewWorkingTimesEnum.Night,
    },
  ];
  public hoursFilter: string[] = [];

  public ratingFilterOptions: FilterCheckboxOption[] = [
    {
      label: '★ ★ ★ ★ ★',
      value: 5,
    },
    {
      label: '★ ★ ★ ★',
      value: 4,
    },
    {
      label: '★ ★ ★',
      value: 3,
    },
    {
      label: '★ ★',
      value: 2,
    },
    {
      label: '★',
      value: 1,
    },
  ];
  public ratingFilter: number[] = [];

  ngOnInit() {
    const initialValue =
      this.breakpointObserver.isMatched('(max-width: 820px)');
    this.isHorizontal = !initialValue;

    this.parseQueryParams();
    this.initFiltersData();
    this.updateRestaurants();

    this.breakpointObserver
      .observe('(max-width: 820px)')
      .subscribe((result) => {
        this.isHorizontal = !result.matches;
        this.changeDetectorRef.detectChanges();
      });

    this.updateCurrentPosition(true);
  }

  public updateCurrentPosition(checkPermissions = false) {
    if (!this.apiHelper.isBrowser) {
      return;
    }

    Geolocation.checkPermissions().then(({ location }) => {
      if (location === 'granted' || !checkPermissions) {
        Geolocation.getCurrentPosition({
          enableHighAccuracy: true,
          timeout: 15000,
          maximumAge: 3 * 60 * 1000,
        })
          .then((position) => {
            this.userLocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };

            if (this.isMapView) {
              this.map?.locateUser();
            }

            this.getNearestRestaurants();
            this.changeDetectorRef.detectChanges();
          })
          .catch((e) => {
            this.userLocationError = e.message;
            this.changeDetectorRef.detectChanges();
          });
      } else {
        this.userLocation = null;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  public getNearestRestaurants() {
    if (!this.userLocation) {
      return;
    }

    this.restaurantsApiService
      .getNearestRestaurants({
        y: this.userLocation.lat,
        x: this.userLocation.lng,
        limit: 10,
      })
      .subscribe((data) => {
        this.nearestRestaurants = data;
        this.changeDetectorRef.detectChanges();
      });
  }

  public parseQueryParams() {
    const params = this.route.snapshot.queryParamMap;
    const awards = params.get('awards')?.split(',') || [];

    if (awards.length > 0) {
      this.awardsFilter = <any>awards;
    }

    const highlights = params.get('highlights')?.split(',') || [];

    if (highlights.length > 0) {
      this.highlightsFilter = <any>highlights;
    }

    const regions = params.get('regions')?.split(',') || [];

    if (regions.length > 0) {
      this.regionsFilter = regions.map(Number);
    }

    const page = parseInt(String(params.get('page')));
    if (page) {
      this.page = page;
    }

    this.changeDetectorRef.markForCheck();
  }

  public restaurantTrackBy(index: number, restaurant: RestaurantPreviewDto) {
    return restaurant.id;
  }

  public onPageChanged(page: number) {
    this.page = page;

    this.updateRestaurants();
  }

  public onFiltersChange() {
    this.page = 1;
    this.paginationComponent?.setPage(this.page, false);

    setTimeout(() => {
      if (this.isMapView) {
        this.updateMapRestaurants();
      } else {
        this.updateRestaurants();
      }
    }, 0);
  }

  public initFiltersData() {
    this.regionsApiService.getRegions().subscribe((regions) => {
      this.regions = regions;

      this.regionsFilterOptions = this.regions.map((r) => ({
        label: r.name,
        value: r.id,
      }));

      this.changeDetectorRef.detectChanges();
    });
  }

  public get totalPages() {
    return Math.ceil(this.totalFilteredItems / this.limit) || 1;
  }

  private getFilters(): SearchRestaurants$Params {
    return {
      priceLevels: this.priceLevelFilter,
      awards: this.awardsFilter,
      highlights: this.highlightsFilter,
      cuisines: this.cuisinesFilter,
      workingTimes: <any>this.hoursFilter,
      regionIds: this.regionsFilter,
      ratings: this.ratingFilter,
    };
  }

  public updateSortBy(val: ListPopoverItem) {
    this.sortBy = <SearchRestaurantsPreview$Params['sortBy']>val.value;
    this.onFiltersChange();
  }

  public updateRestaurants() {
    this.isLoading = true;

    this.destroy$.next();

    this.restaurantsApiService
      .searchRestaurantsPreview({
        limit: this.limit,
        offset: (this.page - 1) * this.limit,
        sortBy: this.sortBy,
        ...this.getFilters(),
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.restaurants = data.items;
          this.totalItems = data.totalItems;
          this.totalFilteredItems = data.totalFilteredItems;
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        },
        complete: () => {
          this.changeDetectorRef.detectChanges();
        },
      });
  }

  public updateMapRestaurants() {
    this.isMapLoading = true;

    this.restaurantsApiService
      .searchRestaurantsPreview({
        ...this.getFilters(),
      })
      .subscribe({
        next: (data) => {
          this.totalItems = data.totalItems;
          this.totalFilteredItems = data.totalFilteredItems;

          this.map?.renderRestaurants(
            data.items.map((p) => ({ data: p })) || [],
          );
          setTimeout(() => {
            if (this.nearestRestaurants) {
              this.map?.locateUser();
            }
          }, 500);
          this.isMapLoading = false;
        },
        error: () => {
          this.isMapLoading = false;
        },
        complete: () => {
          this.changeDetectorRef.detectChanges();
        },
      });
  }
}
