import { NgModule } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AbudubaApiModule } from './generated/abuduba-api';
import { ApiHelper } from './api.helper';

@NgModule({
  imports: [AbudubaApiModule.forRoot({ rootUrl: environment.apiUrl })],
  providers: [ApiHelper],
  exports: [],
})
export class ApiModule {}
