<div id="page_content">
  <div id="nav_bar" [class.open]="isMenuOpen" (click)="closeMenu($event)">
    <div class="bar-body">
      <div class="header">
        <div class="currency" (click)="currencyManager.toggleCurrency()">
          {{ currencyManager.getCurrencyWithSymbol() }}
        </div>

        <div class="close" (click)="isMenuOpen = false">
          <ng-icon name="matCloseOutline"></ng-icon>
        </div>
      </div>

      <div class="line"></div>

      <div class="title">Menu</div>
      <div class="menu">
        <a
          class="menu-item"
          [routerLink]="item.routerLink"
          [queryParams]="item.queryParams"
          *ngFor="let item of menuNavBar"
          (click)="isMenuOpen = false"
          >{{ item.name }}</a
        >
      </div>

      <div class="line"></div>

      <div class="title">Categories</div>
      <div class="categories">
        <a
          class="menu-item"
          *ngFor="let activity of placeActivities"
          routerLink="/places"
          [queryParams]="{ activities: [activity[0]] }"
          >{{ activity[1].name }}</a
        >
      </div>
    </div>
  </div>

  <div id="header">
    <div class="left">
      <div class="menu-toggle" (click)="isMenuOpen = !isMenuOpen">
        <ng-icon name="bootstrapList"></ng-icon>
      </div>

      <a routerLink="/" class="logo-block">
        <img ngSrc="/assets/logo.png" fill alt="Logo" class="logo" />
      </a>
    </div>

    <div class="navigation" [class.search-open]="isSearchOpen">
      <div class="menu">
        <a
          class="menu-item"
          [routerLink]="item.routerLink"
          [queryParams]="item.queryParams"
          *ngFor="let item of menu"
          >{{ item.name }}</a
        >
      </div>

      <div class="actions">
        <div
          class="search"
          appOutsideClick
          (outsideClick)="!autocompleteInput.value && (isSearchOpen = false)"
          [class.open]="isSearchOpen"
          (click)="isSearchOpen = true">
          <ng-icon name="matSearchOutline"></ng-icon>

          <app-autocomplete-input
            placeholder="Search in UAE..."
            #autocompleteInput></app-autocomplete-input>
        </div>

        <div class="currency" (click)="currencyManager.toggleCurrency()">
          <!--        <ng-icon name="bootstrapCurrencyExchange"></ng-icon>-->
          {{ currencyManager.getCurrencyWithSymbol() }}
        </div>
      </div>
    </div>
  </div>

  <div id="content"><router-outlet></router-outlet></div>

  <div id="footer">
    <div class="top-block content-wrap">
      <div class="menu">
        <div class="column">
          <div class="title">Menu</div>

          <div class="items">
            <a
              class="menu-item"
              [routerLink]="item.routerLink"
              [queryParams]="item.queryParams"
              *ngFor="let item of menu"
              >{{ item.name }}</a
            >
          </div>
        </div>

        <div class="column categories">
          <div class="title">Categories</div>
          <div class="items">
            <a
              class="menu-item"
              *ngFor="let activity of placeActivities"
              routerLink="/places"
              [queryParams]="{ activities: [activity[0]] }"
              >{{ activity[1].name }}</a
            >
          </div>
        </div>

        <div class="column">
          <div class="title">About</div>
          <div class="items">
            <a class="menu-item" routerLink="/privacy">Privacy Statement</a>
            <a class="menu-item" routerLink="/terms">Terms & Conditions</a>
            <a class="menu-item" (click)="suggestion.isVisible = true"
              >Leave suggestion</a
            >
            <app-suggestion
              title="Leave suggestion"
              [entityType]="CreateSuggestionDtoEntityTypeEnum.General"
              #suggestion
              [hideButton]="true"></app-suggestion>
          </div>
        </div>
      </div>

      <div class="additional-info">
        <div class="contacts">
          <div class="title">Our contacts</div>
          <div class="email">riyada.planner&#64;gmail.com</div>
        </div>

        <div class="partners">
          <div class="title">Our partners</div>

          <div class="list">
            <img
              ngSrc="/assets/tripadvisor-logo.png"
              width="70"
              height="40"
              alt="tripadvisor" />

            <img
              ngSrc="/assets/viator-logo.png"
              width="70"
              height="25"
              alt="viator" />
          </div>
        </div>
      </div>
    </div>

    <div class="copyright">
      Copyright © 2024 abuduba.com Lusora LLC. All rights reserved
      <br />
      Design by Valeria Galay. valeriagalay1997&#64;gmail.com
    </div>
  </div>
</div>
